import { MetaReducer } from '@ngrx/store';
import { createSelector } from 'reselect';
import { environment } from '../../../environments/environment';
import * as fromMarket from './markets.reducer';
import * as fromCreatedGiveaway from './created-giveaway.reducer'
import * as fromGiveaway from './giveaway.reducer';
import { clone, filter, propEq } from 'ramda';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export interface State {
  markets: fromMarket.State;
  createdGiveaways: fromCreatedGiveaway.State;
  giveaway: fromGiveaway.State;
}

export const reducers = {
  markets: fromMarket.reducer,
  createdGiveaways: fromCreatedGiveaway.reducer,
  giveaway: fromGiveaway.reducer
};

/**
 * Markets state root selector
 */
export const getMarketsState = (state: State) => state.markets;

/**
 * Markets state children selectors
 */
export const getUserName = createSelector(getMarketsState, fromMarket.getUserName);

export const getMarkets = createSelector(getMarketsState, fromMarket.getMarkets);

export const getSelectedMarket = createSelector(getMarketsState, fromMarket.getSelectedMarket);

export const getGlobalBanner = createSelector(getMarketsState, fromMarket.getGlobalBanner);

export const getMaintenance = createSelector(getMarketsState, fromMarket.getMaintenance);

export const getLoading = createSelector(getMarketsState, fromMarket.getLoading);

export const getAuthenticated = createSelector(getMarketsState, fromMarket.getAuthenticated);

export const getGeofences = createSelector(getMarketsState, fromMarket.getGeofences);

/**
 * Created giveaway state root selector
 */

export const getCreatedGiveawayState = (state: State) => state.createdGiveaways;

/**
 * Created giveaway state children selectors
 */

export const getActiveGiveaways = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getActiveGiveaways);

export const getActiveGiveawaysLoading = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getActiveGiveawaysLoading);

export const getActiveGiveawaysError = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getActiveGiveawaysError);

export const getPastGiveaways = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getPastGiveaways);

export const getPastGiveawaysLoading = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getPastGiveawaysLoading);

export const getPastGiveawaysError = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getPastGiveawaysError);

export const getScheduledGiveaways = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getScheduledGiveaways);

export const getScheduledGiveawaysLoading = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getScheduledGiveawaysLoading);

export const getScheduledGiveawaysError = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getScheduledGiveawaysError);

export const getGiveawayPerformances = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getGiveawayPerformances);

export const getFormattedGiveawayPerformances = createSelector(getGiveawayPerformances,
  (giveaways) => {
    if (giveaways) {
      return giveaways.map((giveaway) => {
        let redemptionStartDate = giveaway.redemptionStartDate.slice(0, -5);
        let giveawayName = giveaway.giveawayName;
        if (giveawayName && giveawayName.length > 20) {
          giveawayName = giveawayName.substring(0, 20) + '...';
        }
        return {
          ...giveaway,
          giveawayName,
          redemptionStartDate
        }
      });
    } else {
      return [];
    }
  });

export const getGiveawayPerformancesLoading = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getGiveawayPerformancesLoading);

export const getGiveawayPerformancesError = createSelector(getCreatedGiveawayState, fromCreatedGiveaway.getGiveawayPerformancesError);

export const getMarketByCode = (code) => {
  return createSelector(getMarkets, (markets) => {
    return filter(propEq('marketCode', code), markets)[0];
  });
};

// Format markets to align with imported navbar's variable names
export const getMarketsForNav = createSelector(getMarkets,
  (markets) => {
    return markets.map((market) => {
      return {
        ...market,
        id: market.marketCode ||"",
        description: market.name,
      }
    });
  });

// Format market to align with imported navbar's variable names
export const getSelectedMarketForNav = createSelector(getSelectedMarket,
  (market) => {
    return {
      ...market,
      id: market.marketCode || "",
      description: market.name,
    }
  });

/**
 * Giveaway state root selector
 */
export const getGiveawayState = (state: State) => state.giveaway;

/**
 * Giveaway state children selectors
 */
export const getGiveawayLoading = createSelector(getGiveawayState, fromGiveaway.getLoading);

export const getGiveawayError = createSelector(getGiveawayState, fromGiveaway.getGiveawayError);

export const getEngagementMode = createSelector(getGiveawayState, fromGiveaway.getMode);

export const getEngagementType = createSelector(getGiveawayState, fromGiveaway.getType);

export const getGiveawayId = createSelector(getGiveawayState, fromGiveaway.getId);

export const getGiveawayMarket = createSelector(getGiveawayState, fromGiveaway.getMarket);

export const getRewardTemplates = createSelector(getGiveawayState, fromGiveaway.getRewardTemplates);

export const getRewardTemplateError = createSelector(getGiveawayState, fromGiveaway.getRewardTemplateError);

export const getSelectedReward = createSelector(getGiveawayState, fromGiveaway.getSelectedReward);

export const getEngagementUpdated = createSelector(getGiveawayState, fromGiveaway.getUpdated);

export const getGiveawayHeadline = createSelector(getGiveawayState, fromGiveaway.getHeadline);

export const getGiveawayMessage = createSelector(getGiveawayState, fromGiveaway.getMessage);

export const getClaimStartTime = createSelector(getGiveawayState, fromGiveaway.getClaimStartTime);

export const getClaimEndTime = createSelector(getGiveawayState, fromGiveaway.getClaimEndTime);

export const getClaimStartDate = createSelector(getGiveawayState, fromGiveaway.getClaimStartDate);

export const getClaimEndDate = createSelector(getGiveawayState, fromGiveaway.getClaimEndDate);

export const getRedemptionStartDate = createSelector(getGiveawayState, fromGiveaway.getRedemptionStartDate);

export const getRedemptionEndDate = createSelector(getGiveawayState, fromGiveaway.getRedemptionEndDate);

export const getRewardRedemptionRate = createSelector(getGiveawayState, fromGiveaway.getRewardRedemptionRate);

export const getRedemptionMarkets = createSelector(getGiveawayState, fromGiveaway.getRedemptionMarkets);

export const getRedeemedByProductList = createSelector(getGiveawayState, fromGiveaway.getRedeemedByProductList);

export const getClaimedCount = createSelector(getGiveawayState, fromGiveaway.getClaimedCount);

export const getRedeemedCount = createSelector(getGiveawayState, fromGiveaway.getRedeemedCount);

export const getMarketRedemptionRate = createSelector(getGiveawayState, fromGiveaway.getMarketRedemptionRate);

export const getRedeemedInMarket = createSelector(getGiveawayState, fromGiveaway.getRedeemedInMarket);

export const getRedeemedOutOfMarket = createSelector(getGiveawayState, fromGiveaway.getRedeemedOutOfMarket);

export const getSelectedGeofence = createSelector(getGiveawayState, fromGiveaway.getSelectedGeofence);

export const getSelectedCharge = createSelector(getGiveawayState, fromGiveaway.getSelectedCharge);

export const getDuplicateGiveaways = createSelector(getGiveawayState, fromGiveaway.getDuplicateGiveaways);

export const areAllDatesSelected = createSelector(getClaimStartDate,
  getClaimEndDate,
  getClaimStartTime,
  getClaimEndTime,
  getRedemptionStartDate,
  getRedemptionEndDate,
  (claimStartDate, claimEndDate, claimStartTime, claimEndTime, redemptionStartDate, redemptionEndDate) => {
    return claimStartDate && claimEndDate && claimStartTime && claimEndTime && redemptionStartDate && redemptionEndDate;
  });

export const getGiveawayCopyEdited = createSelector(getGiveawayMessage, getGiveawayHeadline,
  (message, headline) => {
    return message && headline;
  });

export const getGiveawaySendEnabled = createSelector(getGiveawayCopyEdited,
  (copyEdited) => {
    return !!copyEdited;
  });

export const getFormattedGiveaway = createSelector(getGiveawayState,
  (giveawayState) => {
    // Format giveaway to send
    let id = giveawayState.id;
    let offerTemplateId = giveawayState.selectedReward.id;
    let geoAreaId = giveawayState.selectedGeofence.geoAreaId;
    let rewardGeofence = giveawayState.selectedGeofence.geoFenceName;
    let fullMlmAccrual = giveawayState.selectedCharge.value;
    let rewardSummary = giveawayState.selectedReward.offerName;
    let redemptionStartDate = giveawayState.redemptionStartDate.format('YYYY-MM-DD');
    let redemptionEndDate = giveawayState.redemptionEndDate.format('YYYY-MM-DD');

    let timeZoneName = giveawayState.selectedGeofence.timeZoneName;
    let claimStart = momentTimeZone(giveawayState.claimStartDate).tz(timeZoneName);
    let claimEnd = momentTimeZone(giveawayState.claimEndDate).tz(timeZoneName);

    let startTime = mapToMilitaryTime(giveawayState.claimStartTime);
    claimStart.year(giveawayState.claimStartDate.year());
    claimStart.month(giveawayState.claimStartDate.month());
    claimStart.date(giveawayState.claimStartDate.date());
    claimStart.hour(parseInt(startTime.hour, 10));
    claimStart.minute(parseInt(startTime.minute, 10));
    let claimStartAt = claimStart.unix();

    let endTime = mapToMilitaryTime(giveawayState.claimEndTime);
    claimEnd.year(giveawayState.claimEndDate.year());
    claimEnd.month(giveawayState.claimEndDate.month());
    claimEnd.date(giveawayState.claimEndDate.date());
    claimEnd.hour(parseInt(endTime.hour, 10));
    claimEnd.minute(parseInt(endTime.minute, 10));
    let claimEndAt = claimEnd.unix();

    // Format claim and redemptionDetails to match DXE model
    let claimDetails = {
      title: giveawayState.headline,
      subtitle: giveawayState.message,
      messageTemplate: {
        message: null // TODO check with DXE why it is null
      }
    };
    let redeemDetails = claimDetails;

    return {
      id,
      claimStartAt,
      claimEndAt,
      redemptionStartDate,
      redemptionEndDate,
      offerTemplateId,
      rewardSummary,
      claimDetails,
      redeemDetails,
      timeZoneName,
      geoAreaId,
      rewardGeofence,
      fullMlmAccrual
    };
  });

function mapToMilitaryTime(time) {
  time = time.replace(':', '');
  let isPm = time.slice(-2) === 'pm';
  let index = time.length - 3;
  let militaryTime = time.substring(0, index).trim();
  if (militaryTime.substring(0, 2) === '12') {
    if (!isPm) {
      militaryTime = militaryTime.replace(militaryTime.substring(0, 2), '0');
    }
  } else if (isPm) {
    militaryTime = (parseInt(militaryTime, 10) + 1200).toString();
  }
  index = militaryTime.length - 2;
  let hour = militaryTime.slice(0, index);
  let minute = militaryTime.slice(index);
  return { hour, minute };
}

export const getGiveawayDatesError = createSelector(getSelectedGeofence,
  getClaimStartDate,
  getClaimEndDate,
  getClaimStartTime,
  getClaimEndTime,
  getRedemptionStartDate,
  getRedemptionEndDate,
  (selectedGeofence, claimStartDate, claimEndDate, claimStartTime, claimEndTime, redemptionStartDate, redemptionEndDate) => {
    let errors = [];
    if (claimStartDate && claimEndDate && claimStartTime && claimEndTime && redemptionStartDate && redemptionEndDate) {
      let timezone = selectedGeofence.timeZoneName;
      if (timezone) {
        let formattedStartTime = mapToMilitaryTime(claimStartTime);
        let formattedClaimStart = momentTimeZone(claimStartDate).tz(timezone);
        formattedClaimStart.year(moment(claimStartDate).year());
        formattedClaimStart.month(moment(claimStartDate).month());
        formattedClaimStart.date(moment(claimStartDate).date());
        formattedClaimStart.hour(parseInt(formattedStartTime.hour, 10));
        formattedClaimStart.minute(parseInt(formattedStartTime.minute, 10));

        let formattedEndTime = mapToMilitaryTime(claimEndTime);
        let formattedClaimEnd = momentTimeZone(claimEndDate).tz(timezone);
        formattedClaimEnd.year(moment(claimEndDate).year());
        formattedClaimEnd.month(moment(claimEndDate).month());
        formattedClaimEnd.date(moment(claimEndDate).date());
        formattedClaimEnd.hour(parseInt(formattedEndTime.hour, 10));
        formattedClaimEnd.minute(parseInt(formattedEndTime.minute, 10));

        let formattedRedemptionStart = momentTimeZone(redemptionStartDate).tz(timezone);
        formattedRedemptionStart.year(moment(redemptionStartDate).year());
        formattedRedemptionStart.month(moment(redemptionStartDate).month());
        formattedRedemptionStart.date(moment(redemptionStartDate).date());
        formattedRedemptionStart = formattedRedemptionStart.startOf('day');

        let formattedRedemptionEnd = momentTimeZone(redemptionEndDate).tz(timezone);
        formattedRedemptionEnd.year(moment(redemptionEndDate).year());
        formattedRedemptionEnd.month(moment(redemptionEndDate).month());
        formattedRedemptionEnd.date(moment(redemptionEndDate).date());
        formattedRedemptionEnd = formattedRedemptionEnd.startOf('day');

        if (formattedClaimStart.isBefore(moment()) || formattedClaimEnd.isBefore(moment())) {
          errors.push('Claim Dates cannot be before the current day and time.');
        }

        if (formattedRedemptionStart.isBefore(clone(formattedClaimStart).startOf('day'))) {
          errors.push('Redemption Start Date cannot be before Claim Start Date.');
        }

        if (formattedRedemptionEnd.isBefore(clone(formattedClaimEnd).startOf('day'))) {
          errors.push('Redemption End Date cannot be before Claim End Date.');
        }

        if (!formattedClaimEnd.isAfter(formattedClaimStart)) {
          errors.push('Claim End Date/Time must be after Claim Start Date/Time.');
        }
      }
    }
    return errors;
  });

export const getSelectedRewardsEndDateRestriction = createSelector(getSelectedReward,
  (reward) => reward.endDateRestriction);

export const getDisabledRestrictedDates = createSelector(getSelectedRewardsEndDateRestriction,
  (endDate) => endDate ? new Date(endDate) : null);
