import { Action } from '@ngrx/store'

export const GET_MARKETS = '[Markets] Get Markets';
export const GET_MARKETS_SUCCESS = '[Markets] Get Markets Success';
export const UPDATE_SELECTED_MARKET = '[Markets] Update Selected Market';
export const UPDATE_SELECTED_MARKET_BY_ID = '[Markets] Update Selected Market By ID';
export const DISMISS_BANNER = '[Markets] Dismiss Banner';
export const DISMISS_BANNER_SUCCESS = '[Markets] Dismiss Banner Success';

export class GetMarkets implements Action {
  readonly type = GET_MARKETS;

  constructor(public payload: any = '') {
  }
}

export class GetMarketsSuccess implements Action {
  readonly type = GET_MARKETS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateSelectedMarket implements Action {
  readonly type = UPDATE_SELECTED_MARKET;

  constructor(public payload: any) {
  }
}

export class UpdateSelectedMarketById implements Action {
  readonly type = UPDATE_SELECTED_MARKET_BY_ID;

  constructor(public payload: any) {
  }
}

export class DismissBanner implements Action {
  readonly type = DISMISS_BANNER;

  constructor(public payload: any = '') {
  }
}

export class DismissBannerSuccessful implements Action {
  public type = DISMISS_BANNER_SUCCESS;

  constructor(public payload: any = '') {
  }
}

export type Actions
  = GetMarkets
  | GetMarketsSuccess
  | UpdateSelectedMarket
  | UpdateSelectedMarketById
  | DismissBanner
  | DismissBannerSuccessful;
