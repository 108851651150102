import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trim } from 'ramda';

@Component({
  selector: 'data-table-cell',
  templateUrl: './data-table-cell.component.html'
})
export class DataTableCellComponent {
  @Input() public row;
  @Input() public col;
  @Input() public isMobile: boolean = false;

  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public OnEditClick = new EventEmitter<any>();

  public getData(): string {
    return this.row[this.col.prop] ? this.row[this.col.prop] : '';
  }

  public getIcon(): boolean {
    if (!this.col.icon) {
      return false;
    }
    return this.col.iconProp ? this.row[this.col.iconProp] : true;
  }

  public getNumber(): number {
    return this.row[this.col.prop] ? this.row[this.col.prop] : 0;
  }

  public trimData() {
    return trim(this.getData());
  }

  // required for Safari to handle Angular's date pipe correctly
  public formatDate(date) {
    return date.toString().replace(/\s/g, 'T');
  }

  public deleteClicked() {
    this.onDeleteClick.emit();
  }

  public editClicked() {
    this.OnEditClick.emit();
  }

  public lineBreak(date) {
    return date.toString().replace(' ', '<br>');
  }
}
