import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import { Market } from '../../models/Market';
import { GiveawayEngagementConstants } from '../../giveaway-engagement/giveaway-engagement.constants';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GiveawayService {
  public selectedMarket$: Observable<Market>;

  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {
    this.selectedMarket$ = store.select(fromRoot.getSelectedMarket);
  }

  public getRewards() {
    return this.selectedMarket$.pipe(take(1), switchMap((market) => {
      let locationNumber = market.locationNumber;
      let marketCode = market.marketCode;
      let url = `/api/location/${locationNumber}/${marketCode}/offerTemplates`;

      return this.http.get(environment.cfa1gmApiUrl + url).pipe(
        map((rewardTemplate) => rewardTemplate),
        catchError((error) => throwError(error))
      );
    }));
  }

  public createGiveaway(giveaway) {
    return this.selectedMarket$.pipe(take(1), switchMap((market) => {
      let marketCode = market.marketCode;
      let locationNumber = market.locationNumber;
      let url = `/api/market/${marketCode}/${locationNumber}/marketLevelGiveaways`;

      return this.http.post(environment.cfa1gmApiUrl + url, giveaway).pipe(
        map((createdGiveaway: any) => createdGiveaway),
        catchError((error) => {
          // Display constant error text if it is not a duplicate giveaway
          let returnError = !error.error ? error.message : GiveawayEngagementConstants.createGiveawayErrorText;
          return throwError(returnError)
        })
      );
    }));
  }

  public updateGiveaway(giveaway) {
    return this.selectedMarket$.pipe(take(1), switchMap((market) => {
      let locationNumber = market.locationNumber;
      let marketCode = market.marketCode;
      let url = `/api/market/${marketCode}/${locationNumber}/updateMarketLevelGiveaways`;

      return this.http.put(environment.cfa1gmApiUrl + url, giveaway).pipe(
        map((editedGiveaway) => editedGiveaway['responseObject']),
        catchError((error) => throwError(error.statusText))
      );
    }));
  }

  public getGiveawayDetails(giveawayId) {
    return this.selectedMarket$.pipe(take(1), switchMap((market) => {
      let locationNumber = market.locationNumber;
      let marketCode = market.marketCode;
      let url = `/api/market/${marketCode}/${locationNumber}/marketLevelGiveaways/${giveawayId}`;

      return this.http.get(environment.cfa1gmApiUrl + url).pipe(
        map((giveawayDetails) => giveawayDetails['responseObject']),
        catchError((error) => throwError(error.statusText))
      );
    }));
  }

  public deleteGiveaway(giveaway) {
    return this.selectedMarket$.pipe(take(1), switchMap((market) => {
      let locationNumber = market.locationNumber;
      let marketCode = market.marketCode;
      let url = `/api/market/${marketCode}/${locationNumber}/marketLevelGiveaways/${giveaway.id}`;

      return this.http.delete(environment.cfa1gmApiUrl + url).pipe(
        map((giveawayDetails: any) => giveawayDetails['responseObject']),
        catchError((error) => throwError(error.statusText))
      );
    }));
  }
}
