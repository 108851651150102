<mat-toolbar [ngClass]="{'simple-nav__blue-theme': isBlueTheme, 'simple-nav__white-theme': !isBlueTheme}">
  <div fxLayout="row"
       fxFlex="100"
       fxLayoutAlign="space-between center">
    <div *ngIf="isVisibleArrow">
      <button mat-button class="simple-nav__button" (click)="back.emit($event)">
        <i class="material-icons">{{isBackChevron ? 'arrow_back_ios' : 'arrow_back'}}</i>
      </button>
    </div>
    <span [ngClass]="{'simple-nav__mobile-title': isMobile, 'simple-nav__title': !isMobile}"
          fxFlex="auto"
          fxLayoutAlign="center center">
      {{title}}
    </span>
    <div class="simple-nav__empty-block"></div>
  </div>
</mat-toolbar>
