<div class="ngx-data-table__card" *ngIf="!isMobile; else mobile">
  <div class="ngx-data-table">
    <data-table-header
      [rowIdProp]="rowIdProp"
      [isLoading]="isLoading"
      [columns]="columns"
      [currentSort]="currentSort"
      [rows]="rows"
      [showCheckboxes]="showCheckboxes"
      [selectedItems]="selectedItems"
      [allCustomerIds]="allCustomerIds"
      [canSelectAll]="canSelectAll"
      (onSortChange)="sortChange($event)"
      (onSelectAllOnPageOnly)="selectAllRowsOnPageOnly()"
      (onSelectAllCustomers)="onSelectAllCustomers($event)">
    </data-table-header>

    <data-table-body
      [rowIdProp]="rowIdProp"
      [rows]="rows"
      [columns]="columns"
      [isLoading]="isLoading"
      [showCheckboxes]="showCheckboxes"
      [noResultsMessage]="noResultsMessage"
      [actionableErrorText]="actionableErrorText"
      [detailProp]="detailProp"
      [detailColumns]="detailColumns"
      [hasOnClickListeners]="hasOnClickListeners"
      (onRowClick)="rowClicked($event)"
      (onCheckboxClick)="rowSelected($event)"
      (errorTextClick)="errorTextClicked()"
      (onDeleteClick)="deleteClicked($event)"
      (onEditClick)="editClicked($event)">
    </data-table-body>

    <data-table-footer
      *ngIf="showFooter"
      (onPageSizeChange)="pageSizeChange($event)"
      (onPageChange)="pageChange($event)"
      [totalElements]="totalElements"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [currentPage]="currentPage"
      [numberOfPages]="numberOfPages"
      [isMobile]="isMobile">
    </data-table-footer>
  </div>
</div>
<ng-template #mobile>
  <mobile-data-table
    [columns]="columns"
    [rows]="rows"
    [noResultsMessage]="noResultsMessage"
    [currentPage]="currentPage"
    [totalElements]="totalElements"
    [numberOfPages]="numberOfPages"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [showFooter]="showFooter"
    [mobileDeleteEnabled]="mobileDeleteEnabled"
    [mobileEditEnabled]="mobileEditEnabled"
    [actionableErrorText]="actionableErrorText"
    [loading]="isLoading"
    (onPageChange)="pageChange($event)"
    (onRowClick)="rowClicked($event)"
    (errorTextClick)="errorTextClicked()"
    (onDeleteClick)="deleteClicked($event)"
    (onEditClick)="editClicked($event)">
  </mobile-data-table>
</ng-template>
