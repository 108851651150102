import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { Store } from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import { DismissBanner, UpdateSelectedMarketById } from './store/actions/markets.action';
import * as fromRoot from './store/reducers';
import { Market } from './models/Market';
import { take, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import {NavigationService} from "./navigation.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  public maintenance$: Observable<boolean>;
  public userName$: Observable<string>;
  public markets$: Observable<Market[]>;
  public selectedMarket$: Observable<Market>;
  public globalBanner$: Observable<any>;
  public authenticated$:Observable<boolean>;
  public showBanner: boolean = true;
  private refreshSubscription: Subscription;

  constructor(public store: Store<fromRoot.State>,
              private router: Router,
              private sanitizer: DomSanitizer,
              private authService: NavigationService) {
    this.authenticated$ = this.store.select(fromRoot.getAuthenticated)
    this.maintenance$ = this.store.select(fromRoot.getMaintenance);
    this.userName$ = store.select(fromRoot.getUserName);
    this.markets$ = store.select(fromRoot.getMarketsForNav);
    this.selectedMarket$ = store.select(fromRoot.getSelectedMarketForNav);
    this.globalBanner$ = store.select(fromRoot.getGlobalBanner).pipe(map((globalBanner) => {
      const html = globalBanner != undefined ? this.sanitizer.bypassSecurityTrustHtml(globalBanner.bannerText) : null;
      return { ...globalBanner, bannerText: html};
    }));
    this.refreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
      let url = event.urlAfterRedirects
        this.maintenance$.subscribe((activeMaintenance) => {
          if (activeMaintenance) {
            this.router.navigate(['/maintenance']);
          } else {

            this.router.navigate([url]);
          }
        });
      }
    });
  }

  public updateSelectedMarket(marketCode: any) {
    this.selectedMarket$.pipe(take(1)).subscribe(selectedMarket => {
      if (selectedMarket.marketCode !== marketCode) {
        this.store.dispatch(new UpdateSelectedMarketById(marketCode));
      }
    });
  }

  public closeBanner() {
    this.store.dispatch(new DismissBanner());
    this.showBanner = false;
  }

  // Some pages of the application will not have the nav header displayed
  public shouldHideNav() {
    return this.router.url.includes('/maintenance')
      || this.router.url.includes('/giveaway-engagement')
      || this.router.url.includes('/reward-selection')
      || this.router.url.includes('/geofence-selection')
      || this.router.url.includes('/charge-selection')
      || this.router.url.includes('/reward-copy')
      || this.router.url.includes('/giveaway-app-preview')
      || this.router.url.includes('/giveaway-review')
      || this.router.url.includes('/giveaway-confirmation')
      || this.router.url.includes('/past-giveaway');
  }

  public getMargin() {
      return this.shouldHideNav() ? -80 : -10;
  }

  public signOut() {
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

}
