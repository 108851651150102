<div class="actionable-box">
  <div [ngClass]="{'actionable-box__header-disabled': disabled}"
       class="actionable-box__header">
    {{header}}
  </div>
  <i class="actionable-box__disclaimer-text">{{disclaimerText}}</i>
  <mat-nav-list class="actionable-box__list">
    <mat-list-item [disableRipple]="disabled"
                   [ngClass]="{'actionable-box__list-item-disabled': disabled}"
                   (click)="onBoxClick()">
      <img *ngIf="imageSrc" class="actionable-box__image" mat-list-avatar src="{{imageSrc}}"/>
      <h4 mat-line class="actionable-box__summary-header" [ngClass]="{'actionable-box__updated': isUpdated}">
        {{bodyText}}
      </h4>
      <button mat-icon-button *ngIf="!disabled">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-list-item>
  </mat-nav-list>
</div>
