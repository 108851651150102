<div class="mobile-nav-footer__button-container"
     *ngIf="!isError"
     fxLayout="row"
     fxLayoutAlign="space-around center">
  <button mat-button
          class="mobile-nav-footer__cancel-button"
          (click)="backClicked.emit()">
    {{backText}}
  </button>
  <button mat-raised-button
          class="mobile-nav-footer__send-button"
          [disabled]="disableConfirm"
          (click)="confirmClicked.emit()">
    {{confirmText}}
  </button>
</div>
<div class="mobile-nav-footer__button-container"
     *ngIf="isError"
     fxLayout="row"
     fxLayoutAlign="end center">
  <button mat-raised-button
          *ngIf="isError"
          class="mobile-nav-footer__send-button"
          [disabled]="disableConfirm"
          (click)="errorButtonClicked.emit()">
    {{errorButtonText}}
  </button>
  <div fxFlex="20px"></div>
</div>
