<div class="flex-container ngx-data-table__header"
     fxLayout="row"
     fxLayoutAlign="center center">
  <div class="flex-item" [fxFlex]="getFlex()">
    <mat-checkbox class="ngx-data-table__checkbox"
                  *ngIf="showCheckboxes"
                  [checked]="isAllCustomersSelected()"
                  (change)="selectAllClicked($event)">
    </mat-checkbox>
  </div>
  <ng-container *ngFor="let col of columns">
    <div *ngIf="!col.hide"
         [fxFlex]="getColSize(col)"
         (click)="changeSort(col)"
         class="ngx-data-table__padding">
            <span [ngClass]="{'ngx-data-table__header-title': !colIsNotSortable(col)}">
              {{col.title}}
              <mat-icon [@arrowState]="getColState(col)"
                        *ngIf="getColState(col) !== 'none'"
                        class="ngx-data-table__arrow">
                    keyboard_arrow_down
                </mat-icon>
            </span>
    </div>
  </ng-container>
  <!-- Account for space created from the chevron in the data table body-->
  <div fxFlex="32px"></div>
</div>
<div *ngIf="showCheckboxes && canSelectAll && isAllCustomersSelected()"
     class="flex-container ngx-data-table__select-all-message"
     fxLayout="row"
     fxLayoutAlign="center center">
  <span>Selected all {{allCustomerIds.length}} Customers in your Spotlight.</span>
  <span class="ngx-data-table__select-all-message-clickable" (click)="selectAllOnPageOnly()">
        Click here to select only the {{uniqueRowsOnPage.length}} Customers on this page
  </span>
</div>
<div class="flex-container ngx-data-table__progress-bar" fxLayout="row">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate">
  </mat-progress-bar>
</div>
