import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnInit {
  public title;
  public displayText;
  public warningText;
  public confirmText;
  public cancelText;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  public ngOnInit() {
    this.title = this.dialogData.title;
    this.displayText = this.dialogData.displayText;
    this.warningText = this.dialogData.warningText;
    this.confirmText = this.dialogData.confirmText;
    this.cancelText = this.dialogData.cancelText;
  }

  public closeDialog(shouldSend: boolean) {
    this.dialogRef.close(shouldSend);
  }
}
