import { Injectable } from '@angular/core';
import { HomeService } from '../../shared/service/home.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HomeConstants } from '../../home/home.constants';
import * as createdGiveaway from '../actions/created-giveaway.action';
import * as giveaway from '../actions/giveaway.action';

@Injectable()
export class CreatedGiveawayEffects {
  @Effect()
  public getActiveGiveaways$: Observable<any> = this.actions$.pipe(
    ofType(createdGiveaway.GET_ACTIVE_GIVEAWAYS),
    switchMap(() => this.hs.getCreatedGiveaways(HomeConstants.activeMarketsView).pipe(
      map((activeGiveaway) => new createdGiveaway.GetActiveGiveawaySuccessAction(activeGiveaway)),
      catchError(() => of(new createdGiveaway.GetCreatedGiveawayErrorAction(HomeConstants.activeMarketsView)))
    ))
  );

  @Effect()
  public getPastGiveaways$: Observable<any> = this.actions$.pipe(
    ofType(createdGiveaway.GET_PAST_GIVEAWAYS),
    switchMap(() => this.hs.getCreatedGiveaways(HomeConstants.pastMarketsView).pipe(
      map((pastGiveaway) => new createdGiveaway.GetPastGiveawaySuccessAction(pastGiveaway)),
      catchError(() => of(new createdGiveaway.GetCreatedGiveawayErrorAction(HomeConstants.pastMarketsView)))
    ))
  );

  @Effect()
  public getFutureGiveaways$: Observable<any> = this.actions$.pipe(
    ofType(createdGiveaway.GET_FUTURE_GIVEAWAYS, giveaway.DELETE_GIVEAWAY_SUCCESS),
    switchMap(() => this.hs.getCreatedGiveaways(HomeConstants.futureMarketsView).pipe(
      map((futureGiveaway) => new createdGiveaway.GetFutureGiveawaySuccessAction(futureGiveaway)),
      catchError(() => of(new createdGiveaway.GetCreatedGiveawayErrorAction(HomeConstants.futureMarketsView)))
    ))
  );

  @Effect()
  public getGiveawayPerformances$: Observable<any> = this.actions$.pipe(
    ofType(createdGiveaway.GET_GIVEAWAY_PERFORMANCE),
    switchMap(() => this.hs.getGiveawayPerformances().pipe(
      map((giveawayPerformances) => new createdGiveaway.GetGiveawayPerformanceSuccessAction(giveawayPerformances)),
      catchError((error) => of(new createdGiveaway.GetGiveawayPerformanceErrorAction(error)))
    ))
  );

  constructor(
    private actions$: Actions,
    private hs: HomeService
  ) {
  }
}

