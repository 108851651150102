import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent {
  @Input() public resultsText: string;
  @Input() public placeholderText: string;
  @Input() public isMobile: boolean;

  @Output() public searchTermChanged = new EventEmitter();

  public searchTerm = '';
}
