import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-message',
  styleUrls: ['./error-message.component.scss'],
  template: `
    <div class="error-message"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="space-around center">
        <mat-icon class="error-message__icon">sentiment_very_dissatisfied</mat-icon>
        <div class="error-message__tagline">Well, this is embarrassing...</div>
        <div class="error-message__error">{{error}}</div>
    </div>
  `
})

export class ErrorMessageComponent {
  @Input() public error: string;
}
