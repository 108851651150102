import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'simple-nav',
  templateUrl: './simple-nav.component.html',
  styleUrls: ['./simple-nav.component.scss']
})
export class SimpleNavComponent {
  @Input() public title: string;
  @Input() public isMobile: boolean = false;
  @Input() public isBackChevron: boolean = true;
  @Input() public isVisibleArrow: boolean = true;
  @Input() public isBlueTheme: boolean = false;

  @Output() public back = new EventEmitter<any>();
}
