import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'cfaone-giveaway-ui/environments/environment';

import { catchError, tap } from 'rxjs/operators';
import {from, Observable, throwError} from 'rxjs';
import Bugsnag from "@bugsnag/js";
import { SharedConstants } from "../shared.constants";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // Only add an access token to allowed origins
    const allowedOrigins = environment.urlsForAuthHeader;
    if (allowedOrigins.some(url => request.urlWithParams.includes(url))) {
      const accessToken = this.oktaAuth.getAccessToken() || '';
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken
        }
      })
    }
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.status === 200) {
          // If you want to take an action on a success response, do it here.
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (allowedOrigins.some(url => request.urlWithParams.includes(url))) {
          if (error.status === 401 || error.status === 403 || error.status === 0) {
            if(this.oktaAuth.getAccessToken()){
              Bugsnag.notify("User not Authorized - Prompting Login");
              this.oktaAuth.setOriginalUri(window.location.href);
              this.oktaAuth.signInWithRedirect()
            }

          }
        }
        return throwError(error);
      })

    ).toPromise();
  }
}
