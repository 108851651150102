import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../store/reducers';
import { HomeConstants } from '../../home/home.constants';

@Component({
  selector: 'giveaway-html-preview',
  templateUrl: './giveaway-html-preview.component.html',
  styleUrls: ['./giveaway-html-preview.component.scss']
})
export class GiveawayHtmlPreviewComponent {
  @Input() public headline: any;
  @Input() public message: any;
  @Input() public image: any;
  @Input() public loading: boolean;
  @Input() public title: string;
  @Input() public isVisibleArrow: boolean;
  @Input() public isBackChevron: boolean;

  @Output() public onBackClick = new EventEmitter<any>();

  public isMobile$: Observable<boolean>;
  public defaultHeadline: string = 'A Reward From [Market Name]';
  public defaultMessage: string = 'Enjoy this reward, on us.';

  constructor(public store: Store<fromRoot.State>, private breakpointObserver: BreakpointObserver) {
    this.isMobile$ = breakpointObserver.observe(HomeConstants.tabletScreenSizes).pipe(
      map((result) => result.matches)
    );
  }
}
