import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mobile-data-table',
  templateUrl: './mobile-data-table.component.html',
  styleUrls: ['./mobile-data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDataTableComponent {
  @Input() public rows: any[];
  @Input() public noResultsMessage: string;
  @Input() public loading: boolean = false;
  @Input() public numberOfPages: number;
  @Input() public currentPage: number;
  @Input() public totalElements: number;
  @Input() public pageSizeOptions: number[];
  @Input() public pageSize: number;
  @Input() public showFooter: boolean = true;
  @Input() public actionableErrorText: string;
  @Input() public mobileDeleteEnabled: boolean = false;
  @Input() public mobileEditEnabled: boolean = false;

  @Input()
  set columns(columns: any[]) {
    if (columns) {
      this.mobileColumns = columns.filter((col) => {
        return col.mobile;
      });
    }
  }

  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onPageChange = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onEditClick = new EventEmitter<any>();

  private mobileColumns: any[];

  public rowClicked(rowData: any) {
    this.onRowClick.emit(rowData);
  }

  public isDataAvailable() {
    return this.rows
      && this.rows.length
      && this.rows.length > 0;
  }

  public changeCurrentPage(page) {
    this.onPageChange.emit(page);
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public editClicked(row) {
    this.onEditClick.emit(row);
  }
}
