import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DataTableConstants } from './data-table.constants';
import * as R from 'ramda';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit {
  @Input() public frontEndPaging: boolean = false;
  @Input() public rowIdProp: string = 'id';

  @Input()
  set columns(columns: any[]) {
    this._columns = columns;
    const firstColumn = columns[0] || {};
    this.currentSort = {
      prop: firstColumn.prop,
      order: 'asc',
    };
  }

  get columns() {
    return this._columns;
  }

  @Input() public detailColumns: any[];
  @Input() public totalElements: number;
  @Input() public numberOfPages: number;
  @Input() public allCustomerIds: any[];
  @Input() public pageSize: number = DataTableConstants.defaultPageSize;
  @Input() public pageSizeOptions: number[];
  @Input() public sort: any;
  @Input() public currentPage: number;
  @Input() public currentSort: any = {};
  @Input() public isLoading: boolean;
  @Input() public noResultsMessage: string;
  @Input() public detailProp: string;
  @Input() public isDetailView: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public disableSelect: boolean = false;
  @Input() public actionableErrorText: string;
  @Input() public canSelectAll: boolean = true;
  @Input() public mobileDeleteEnabled: boolean = false;
  @Input() public mobileEditEnabled: boolean = false;

  @Input()
  set rows(rows: any[]) {
    if (rows && rows.length > 0) {
      this._rows = this.setRows(rows);
      if (this.frontEndPaging) {
        this.allCustomerIds = R.pluck(this.rowIdProp, this.rows);
        this.currentPage = 0;
        this.allRows = this.sortData(this.currentSort, this._rows);
        this.sliceRowsBasedOnCurrentPage();
      }
    } else {
      this._rows = [];
    }
  }

  get rows() {
    return this._rows;
  }

  @Input()
  set view(view: any) {
    // TODO: move this logic to the reducer
    this.previousView = view;
  }

  @Input()
  set initiallySelectedCustomers(initialCustomers: any[]) {
    this.selectedItems = R.clone(initialCustomers);
    this.rows = this.setRows(this.rows);
  }

  @Output() public onPageChange = new EventEmitter<number>();
  @Output() public onPageSizeChange = new EventEmitter<number>();
  @Output() public onSortChange = new EventEmitter<any>();
  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onSelection = new EventEmitter<any[]>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onEditClick = new EventEmitter<any>();

  public selectedItems: any[] = [];
  public hasOnClickListeners: boolean;
  public _rows: any[];
  public _columns: any[];
  public previousView;
  public allRows: any[];

  public ngOnInit() {
    this.hasOnClickListeners = this.onRowClick.observers.length > 0;
  }

  get showCheckboxes(): boolean {
    return !this.disableSelect && this.onSelection.observers.length > 0;
  }

  get showFooter(): boolean {
    return this.onPageChange.observers.length > 0 ||
      this.onPageSizeChange.observers.length > 0 ||
      this.frontEndPaging;
  }

  public onSelectAllCustomers(val: boolean) {
    this.selectAllCustomers(val);
  }

  public rowSelected(rowData: any) {
    this.addOrRemoveSelection(rowData);
    this.onSelection.emit(this.selectedItems);
  }

  public rowClicked(rowData: any) {
    this.onRowClick.emit(rowData);
  }

  public sortChange(event) {
    if (this.onSortChange.observers.length > 0) {
      this.onSortChange.emit(event);
    } else {
      this.allRows = this.sortData(event, this.allRows);
      this.sliceRowsBasedOnCurrentPage();
    }
  }

  public pageChange(event) {
    this.currentPage = event;
    if (this.frontEndPaging) {
      this.sliceRowsBasedOnCurrentPage();
    } else {
      this.onPageChange.emit(event);
    }
  }

  public pageSizeChange(event) {
    this.onPageSizeChange.emit(event);
  }

  public errorTextClicked() {
    this.errorTextClick.emit();
  }

  public sortData(sort, data) {
    if (data) {
      const isString = R.propIs(String, sort.prop);
      const ignoreCase = R.compose(R.toLower, R.prop(sort.prop));
      const sortFn = R.sortBy(R.ifElse(
        isString,
        ignoreCase,
        R.prop(sort.prop)
      ));

      let sortedRows = sortFn(data);
      if (sort.order === 'desc') {
        sortedRows = R.reverse(sortedRows);
      }
      return sortedRows;
    } else {
      return [];
    }
  }

  public sliceRowsBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.totalElements) {
      this._rows = this.allRows.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this._rows = this.allRows.slice(nextRowIndex, this.totalElements);
    }
    this._rows = this.setRows(this._rows);
  }

  // TODO move this to a util function
  public addOrRemoveSelection(row) {
    let findIndex = this.selectedItems
      .findIndex((val) => val === row[this.rowIdProp]);

    if (findIndex > -1) {
      this.selectedItems.splice(findIndex, 1);
    } else {
      this.selectedItems.push(row[this.rowIdProp]);
    }
    this._rows = [...this._rows];
    this.selectedItems = [...this.selectedItems];
  }

  public clearSelections() {
    this.selectedItems = [];
    this.onSelection.emit(this.selectedItems);
  }

  public setRows(rows) {
    let ids = this.selectedItems;
    return rows.reduce((curr, next) => {
      next = { ...next };
      if (ids.indexOf(next[this.rowIdProp]) > -1) {
        next = { ...next, state: true };
      }
      curr.push(next);
      return curr;
    }, []);
  }

  public selectAllRowsOnPageOnly() {
    this.selectedItems = [];
    this._rows = this._rows.map((row) => {
      this.selectedItems.push(row[this.rowIdProp]);
      row = { ...row, state: true };
      return row;
    });
    this.selectedItems = R.uniq(this.selectedItems);
    this.onSelection.emit(this.selectedItems);
  }

  public selectAllCustomers(isChecked) {
    this._rows = this._rows.map((row) => {
      return { ...row, state: isChecked };
    });
    if (isChecked === true) {
      this.selectedItems = R.uniq(R.concat(this.selectedItems, this.allCustomerIds));
      this.onSelection.emit(this.selectedItems);
    } else {
      this.clearSelections();
    }
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public editClicked(row) {
    this.onEditClick.emit(row);
  }
}
