import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'actionable-box',
  templateUrl: './actionable-box.component.html',
  styleUrls: ['./actionable-box.component.scss']
})
export class ActionableBoxComponent {
  @Input() public header: string;
  @Input() public disclaimerText: string;
  @Input() public imageSrc: string;
  @Input() public bodyText: string;
  @Input() public disabled: boolean = false;
  @Input() public isUpdated: boolean = false;

  @Output() public boxClicked = new EventEmitter<any>();

  public onBoxClick() {
    this.boxClicked.emit();
  }
}
