import { Action } from '@ngrx/store';

export const GET_ACTIVE_GIVEAWAYS = '[Created Giveaway] Get Active Giveaway';
export const GET_ACTIVE_GIVEAWAYS_SUCCESS = '[Created Giveaway] Get Active Giveaway Success';
export const GET_PAST_GIVEAWAYS = '[Created Giveaway] Get Past Giveaway';
export const GET_PAST_GIVEAWAYS_SUCCESS = '[Created Giveaway] Get Past Giveaway Success';
export const GET_FUTURE_GIVEAWAYS = '[Created Giveaway] Get Future Giveaway';
export const GET_FUTURE_GIVEAWAYS_SUCCESS = '[Created Giveaway] Get Future Giveaway Success';
export const GET_CREATED_GIVEAWAYS_ERROR = '[Created Giveaway] Get Created Giveaways Error';
export const GET_GIVEAWAY_PERFORMANCE = '[Created Giveaway] Get Giveaway Performance';
export const GET_GIVEAWAY_PERFORMANCE_SUCCESS = '[Created Giveaway] Get Giveaway Performance Success';
export const GET_GIVEAWAY_PERFORMANCE_ERROR = '[Created Giveaway] Get Giveaway Performance Error';

export class GetActiveGiveawayAction implements Action {
  readonly type = GET_ACTIVE_GIVEAWAYS;

  constructor(public payload: any = '') {
  }
}

export class GetActiveGiveawaySuccessAction implements Action {
  readonly type = GET_ACTIVE_GIVEAWAYS_SUCCESS;

  constructor(public payload: any = '') {
  }
}

export class GetPastGiveawayAction implements Action {
  readonly type = GET_PAST_GIVEAWAYS;

  constructor(public payload: any = '') {
  }
}

export class GetPastGiveawaySuccessAction implements Action {
  readonly type = GET_PAST_GIVEAWAYS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetFutureGiveawayAction implements Action {
  readonly type = GET_FUTURE_GIVEAWAYS;

  constructor(public payload: any = '') {
  }
}

export class GetFutureGiveawaySuccessAction implements Action {
  readonly type = GET_FUTURE_GIVEAWAYS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetCreatedGiveawayErrorAction implements Action {
  readonly type = GET_CREATED_GIVEAWAYS_ERROR;

  constructor(public payload: any = '') {
  }
}

export class GetGiveawayPerformanceAction implements Action {
  readonly type = GET_GIVEAWAY_PERFORMANCE;

  constructor(public payload: any = '') {
  }
}

export class GetGiveawayPerformanceSuccessAction implements Action {
  readonly type = GET_GIVEAWAY_PERFORMANCE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetGiveawayPerformanceErrorAction implements Action {
  readonly type = GET_GIVEAWAY_PERFORMANCE_ERROR;

  constructor(public payload: any) {
  }
}

export type Actions
  = GetActiveGiveawayAction
  | GetActiveGiveawaySuccessAction
  | GetPastGiveawayAction
  | GetPastGiveawaySuccessAction
  | GetFutureGiveawayAction
  | GetFutureGiveawaySuccessAction
  | GetCreatedGiveawayErrorAction
  | GetGiveawayPerformanceAction
  | GetGiveawayPerformanceSuccessAction
  | GetGiveawayPerformanceErrorAction;
