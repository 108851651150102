import * as giveaway from '../actions/giveaway.action';
import * as markets from '../actions/markets.action';
import { GiveawayEngagementConstants } from '../../giveaway-engagement/giveaway-engagement.constants';
import * as momentTimeZone from 'moment-timezone';
import { find, propEq } from 'ramda';

export interface State {
  loading: boolean,
  mode: string,
  type: string,
  id: string,
  market: any,
  headline: string,
  message: string,
  claimStartTime: any,
  claimEndTime: any,
  claimStartDate: any,
  claimEndDate: any,
  redemptionStartDate: any,
  redemptionEndDate: any,
  rewardRedemptionRate: any,
  redeemedByProductList: any,
  marketRedeemedRate: any,
  rewardTemplates: any[],
  selectedReward: any,
  updated: any,
  giveawayError,
  rewardTemplateError: any,
  selectedGeofence: any,
  selectedCharge: any,
  duplicateGiveaways?: any[]
}

export const initialState: State = {
  loading: true,
  mode: null,
  type: null,
  id: null,
  market: null,
  headline: null,
  message: null,
  claimStartTime: null,
  claimEndTime: null,
  claimStartDate: null,
  claimEndDate: null,
  redemptionStartDate: null,
  redemptionEndDate: null,
  rewardRedemptionRate: {
    redemptionRate: null,
    claimedCount: null,
    redeemedCount: null
  },
  redeemedByProductList: null,
  marketRedeemedRate: {
    redemptionRate: null,
    reedeemedInMarket: null,
    redeemedOutOfMarket: null
  },
  rewardTemplates: [],
  selectedReward: {},
  updated: {},
  giveawayError: null,
  rewardTemplateError: null,
  selectedGeofence: {},
  selectedCharge: null
};

function mapFromMilitaryTime(time) {
  if (time === '000') {
    return '12:00 am';
  } else if (time === '015') {
    return '12:15 am';
  } else if (time === '030') {
    return '12:30 am';
  }  else if (time === '045') {
    return '12:45 am';
  } else if (time === '1200') {
    return '12:00 pm';
  } else if (time === '1215') {
    return '12:15 pm';
  } else if (time === '1230') {
    return '12:30 pm';
  } else if (time === '1245') {
    return '12:45 pm';
  } else if (time < 1200) {
    let index = time.toString().length - 2;
    let displayTime = time.toString().slice(0, index) + ':' + time.toString().slice(index);
    return displayTime + ' am';
  } else {
    let displayTime = (time - 1200) + ' pm';
    let index = displayTime.length - 5;
    return displayTime.slice(0, index) + ':' + displayTime.slice(index);
  }
}

export function reducer(state = initialState, action: giveaway.Actions | markets.Actions): State {
  switch (action.type) {
    case markets.GET_MARKETS_SUCCESS: {
      return { ...state, loading: false };
    }

    case markets.UPDATE_SELECTED_MARKET: {
      const market = action.payload;

      return { ...initialState, market };
    }

    case giveaway.START_ENGAGEMENT_FLOW: {
      const mode = action.payload;

      return { ...initialState, mode, loading: (mode === GiveawayEngagementConstants.editMode) };
    }

    case giveaway.START_EDIT_FLOW: {
      const { id, type } = action.payload;

      return {
        ...initialState,
        id,
        type,
        mode: GiveawayEngagementConstants.editMode
      };
    }

    case giveaway.GET_GIVEAWAY_DETAILS: {
      const { type } = action.payload;
      return {
        ...initialState,
        type,
        mode: GiveawayEngagementConstants.reviewMode
      };
    }

    case giveaway.GET_GIVEAWAY_DETAILS_SUCCESS: {
      const details = action.payload[0];
      const timezone = details.timeZoneName;

      let claimStartDate = momentTimeZone.unix(details.claimStartAt).tz(timezone);
      let claimEndDate = momentTimeZone.unix(details.claimEndAt).tz(timezone);

      // Redemption dates don't have a time associated with them so
      // handle them in user's local timezone for simplicity
      let redemptionStartDate = momentTimeZone(details.redemptionStartDate);
      let redemptionEndDate = momentTimeZone(details.redemptionEndDate);

      // If minutes is 0, we need to add an extra 0 for the military time map to work correctly
      let startMinutes = claimStartDate.minutes().toString().length === 1
        ? `${claimStartDate.minutes()}0` : claimStartDate.minutes();
      let endMinutes = claimEndDate.minutes().toString().length === 1
        ? `${claimEndDate.minutes()}0` : claimEndDate.minutes();

      let claimStartTime = mapFromMilitaryTime(`${claimStartDate.hours()}${startMinutes}`);
      let claimEndTime = mapFromMilitaryTime(`${claimEndDate.hours()}${endMinutes}`);

      let headline = details.claimDetails ? details.claimDetails.title : null;
      let message = details.claimDetails ? details.claimDetails.subtitle : null;
      let rewardRedemptionRate = details.rewardRedemptionRate ? details.rewardRedemptionRate : initialState.rewardRedemptionRate;
      let marketRedeemedRate = details.marketRedeemedRate ? details.marketRedeemedRate : initialState.marketRedeemedRate;
      let redeemedByProductList = details.redeemedByProductList ? details.redeemedByProductList : initialState.redeemedByProductList;

      // Handle null values
      let fullMlmAccrualFlag = !!details.fullMlmAccrual;
      let selectedCharge = find(propEq('value', fullMlmAccrualFlag))(GiveawayEngagementConstants.crossChargeOptions);

      return {
        ...state,
        headline,
        message,
        claimStartDate,
        claimEndDate,
        claimStartTime,
        claimEndTime,
        redemptionStartDate,
        redemptionEndDate,
        rewardRedemptionRate,
        marketRedeemedRate,
        redeemedByProductList,
        selectedGeofence: {
          geoFenceName: details.name,
          geoAreaId: details.geoAreaId,
          timeZoneName: details.timeZoneName
        },
        selectedReward: {
          offerName: details.treatName,
          id: details.offerTemplateId,
          imageURL: details.treatImage,
          details: details.details
        },
        selectedCharge,
        updated: { template: true, geofence: true, charge: true },
        loading: false
      };
    }

    case giveaway.GET_GIVEAWAY_DETAILS_ERROR:
    case giveaway.SAVE_UPDATED_GIVEAWAY_ERROR:
    case giveaway.CREATE_GIVEAWAY_ERROR: {
      const giveawayError = action.payload;

      return { ...state, giveawayError, loading: false };
    }

    case giveaway.UPDATE_GIVEAWAY: {
      const payload = action.payload;
      if (payload.claimStartDate){
        payload.redemptionStartDate = payload.claimStartDate;
      }

      return { ...state, ...payload };
    }

    case giveaway.GET_REWARD_TEMPLATES:
    case giveaway.SAVE_UPDATED_GIVEAWAY:
    case giveaway.CREATE_GIVEAWAY: {
      return { ...state, loading: true };
    }

    case giveaway.GET_REWARD_TEMPLATES_SUCCESS: {
      const rewardTemplates = action.payload;

      return { ...state, rewardTemplates, loading: false };
    }

    case giveaway.SELECT_REWARD: {
      const reward = action.payload;
      const updated = { ...state.updated, template: true };

      return {
        ...state,
        claimEndDate: null,
        claimStartDate: null,
        claimEndTime: null,
        claimStartTime: null,
        redemptionEndDate: null,
        redemptionStartDate: null,
        selectedReward: {
          ...state.selectedReward,
          imageURL: reward.imageURL,
          offerName: reward.offerName,
          details: reward.details,
          id: reward.id,
          endDateRestriction: reward.endDateRestriction
        },
        updated,
      };
    }

    case giveaway.GET_REWARD_TEMPLATES_ERROR: {
      const rewardTemplateError = action.payload;

      return { ...state, rewardTemplateError: rewardTemplateError, loading: false }
    }

    case giveaway.CREATE_GIVEAWAY_SUCCESS:
    case giveaway.CLEAR_GIVEAWAY_DATA: {
      return { ...initialState, market: state.market };
    }

    case giveaway.CREATE_GIVEAWAY_DUPLICATE: {
      const duplicateGiveaways = action.payload;

      return { ...state, duplicateGiveaways, loading: false };
    }

    case giveaway.CLEAR_DUPLICATE_GIVEAWAYS: {
      return { ...state, duplicateGiveaways: initialState.duplicateGiveaways };
    }

    case giveaway.DELETE_GIVEAWAY: {
      return { ...state, loading: true };
    }

    case giveaway.DELETE_GIVEAWAY_SUCCESS: {
      return { ...state, loading: false };
    }

    case giveaway.SELECT_GEOFENCE: {
      const selectedGeofence = action.payload;
      const updated = { ...state.updated, geofence: true };

      return { ...state, selectedGeofence, updated };
    }

    case giveaway.SELECT_CHARGE: {
      const selectedCharge = action.payload;
      const updated = { ...state.updated, charge: true };

      return { ...state, selectedCharge, updated };
    }

    default:
      return state;
  }
}

export const getLoading = (state: State) => state.loading;

export const getGiveawayError = (state: State) => state.giveawayError;

export const getMode = (state: State) => state.mode;

export const getType = (state: State) => state.type;

export const getId = (state: State) => state.id;

export const getMarket = (state: State) => state.market;

export const getRewardTemplates = (state: State) => state.rewardTemplates;

export const getSelectedReward = (state: State) => state.selectedReward;

export const getUpdated = (state: State) => state.updated;

export const getHeadline = (state: State) => state.headline;

export const getMessage = (state: State) => state.message;

export const getRewardTemplateError = (state: State) => state.rewardTemplateError;

export const getClaimStartTime = (state: State) => state.claimStartTime;

export const getClaimEndTime = (state: State) => state.claimEndTime;

export const getClaimStartDate = (state: State) => state.claimStartDate;

export const getClaimEndDate = (state: State) => state.claimEndDate;

export const getRedemptionStartDate = (state: State) => state.redemptionStartDate;

export const getRedemptionEndDate = (state: State) => state.redemptionEndDate;

export const getRewardRedemptionRate = (state: State) => state.rewardRedemptionRate.redemptionRate;

export const getRedemptionMarkets = (state: State) => state.marketRedeemedRate;

export const getRedeemedByProductList = (state: State) => state.redeemedByProductList;

export const getClaimedCount = (state: State) => state.rewardRedemptionRate.claimedCount;

export const getRedeemedCount = (state: State) => state.rewardRedemptionRate.redeemedCount;

export const getMarketRedemptionRate = (state: State) => state.marketRedeemedRate.redemptionRate;

export const getRedeemedInMarket = (state: State) => state.marketRedeemedRate.reedeemedInMarket;

export const getRedeemedOutOfMarket = (state: State) => state.marketRedeemedRate.redeemedOutOfMarket;

export const getSelectedGeofence = (state: State) => state.selectedGeofence;

export const getSelectedCharge = (state: State) => state.selectedCharge;

export const getDuplicateGiveaways = (state: State) => state.duplicateGiveaways;
