<simple-nav [title]="title"
            [isMobile]="isMobile$ | async"
            [isVisibleArrow]="isVisibleArrow"
            [isBackChevron]="isBackChevron"
            (back)="onBackClick.emit($event)">
</simple-nav>
<div *ngIf="!loading" class="giveaway-html-preview" fxLayoutAlign="space-around">
  <div [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-phone' : 'giveaway-html-preview__phone'">
    <div [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-screen' : 'giveaway-html-preview__screen'">
      <div *ngIf="headline"
           [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-headline' : 'giveaway-html-preview__headline'"
           fxLayoutAlign="center center">{{headline}}</div>
      <div *ngIf="!headline"
           [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-headline' : 'giveaway-html-preview__headline'"
           fxLayoutAlign="center center">{{defaultHeadline}}</div>
      <div
        [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-reward-image-container' : 'giveaway-html-preview__reward-image-container'"
        fxLayoutAlign="center center">
        <img *ngIf="image"
             [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-reward-image' : 'giveaway-html-preview__reward-image'"
             fxLayoutAlign="center center" src="{{image}}">
        <img *ngIf="!image"
             [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-reward-image' : 'giveaway-html-preview__reward-image'"
             fxLayoutAlign="center center"
             src="https://www.cfacdn.com/img/order/menu/Mobile/Entrees/Parent/Classic_Sandwich.png">
      </div>
      <div *ngIf="message"
           [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-message' : 'giveaway-html-preview__message'"
           fxLayoutAlign="center center">{{message}}</div>
      <div *ngIf="!message"
           [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-message' : 'giveaway-html-preview__message'"
           fxLayoutAlign="center center">{{defaultMessage}}</div>
      <div
        [ngClass]="(isMobile$ | async) ? 'giveaway-html-preview__mobile-button' : 'giveaway-html-preview__button'"></div>
    </div>
  </div>
</div>
