<div class="confirmation-dialog">
  <div *ngIf="title" class="confirmation-dialog__title" [innerHTML]=title></div>
  <div class="confirmation-dialog__text" [innerHTML]=displayText></div>
  <div class="confirmation-dialog__warning-text" [innerHTML]=warningText></div>
  <div md-dialog-actions fxLayout="row" fxLayoutAlign="space-around center" class="confirmation-dialog__wrapper">
    <button mat-button (click)="closeDialog(false)" class="confirmation-dialog__cancel-button">{{cancelText}}</button>
    <button mat-raised-button (click)="closeDialog(true)" class="confirmation-dialog__send-button">
      {{confirmText}}
    </button>
  </div>
</div>
