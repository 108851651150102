<cfa-navigation-wrapper [ngClass]="{'app__wrapper-hidden': shouldHideNav() || !(authenticated$|async)}"
                        [appName]="'Chick-fil-A One Giveaway Manager'"
                        [userName]="userName$ | async"
                        [ignoreContainerPadding]="true"
                        [activeSearchObject]="selectedMarket$ | async"
                        [navSearchObjectName]="'Markets'"
                        [searchObjects]="markets$ | async"
                        (searchResultSelected)="updateSelectedMarket($event)"
                        (signout)="signOut()">
  <cfa-nav-content [ngStyle]="{'visibility': 'visible'}">
    <div [ngStyle]="{'margin-top': getMargin() +'px'}">
      <div #globalBanner
           *ngIf="(authenticated$|async) && (globalBanner$ | async).bannerText != null && showBanner"
           class="app__notification-banner"
           [ngClass]="{'app__critical': (globalBanner$ | async).critical}">
        <span fxFlex class="app__banner-text" [innerHTML]="(globalBanner$ | async).bannerText"></span>
        <div fxFlexAlign="start" fxFlex="25px">
          <mat-icon class="app__close" (click)="closeBanner()">close</mat-icon>
        </div>
      </div>
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </cfa-nav-content>
</cfa-navigation-wrapper>
