import { Action } from '@ngrx/store'

export const START_ENGAGEMENT_FLOW = '[Giveaway] Start Engagement Flow';
export const START_EDIT_FLOW = '[Giveaway] Start Edit Flow';
export const GET_GIVEAWAY_DETAILS = '[Giveaway] Get Giveaway Details';
export const GET_GIVEAWAY_DETAILS_SUCCESS = '[Giveaway] Get Giveaway Details Success';
export const GET_GIVEAWAY_DETAILS_ERROR = '[Giveaway] Get Giveaway Details Error';
export const SAVE_UPDATED_GIVEAWAY = '[Giveaway] Save Updated Giveaway';
export const SAVE_UPDATED_GIVEAWAY_SUCCESS = '[Giveaway] Save Updated Giveaway Success';
export const SAVE_UPDATED_GIVEAWAY_ERROR = '[Giveaway] Save Updated Giveaway Error';
export const UPDATE_DATES = '[Giveaway] Update Dates';
export const UPDATE_GIVEAWAY = '[Giveaway] Update Giveaway';
export const GET_REWARD_TEMPLATES = '[Giveaway] Get Reward Templates';
export const SELECT_REWARD = '[Giveaway] Select Reward';
export const GET_REWARD_TEMPLATES_SUCCESS = '[Giveaway] Get Reward Templates Success';
export const GET_REWARD_TEMPLATES_ERROR = '[Giveaway] Get Reward Templates Error';
export const CREATE_GIVEAWAY = '[Giveaway] Create Giveaway';
export const CREATE_GIVEAWAY_SUCCESS = '[Giveaway] Create Giveaway Success';
export const CREATE_GIVEAWAY_ERROR = '[Giveaway] Create Giveaway Error';
export const CREATE_GIVEAWAY_DUPLICATE = '[Giveaway] Create Giveaway Duplicate';
export const CLEAR_DUPLICATE_GIVEAWAYS = '[Giveaway] Clear Duplicate Giveaway';
export const CLEAR_GIVEAWAY_DATA = '[Giveaway] Clear Giveaway Data';
export const DELETE_GIVEAWAY = '[Giveaway] Delete Giveaway';
export const DELETE_GIVEAWAY_SUCCESS = '[Giveaway] Delete Giveaway Success';
export const DELETE_GIVEAWAY_ERROR = '[Giveaway] Delete Giveaway Error';
export const SELECT_GEOFENCE = '[Giveaway] Select Geofence';
export const SELECT_CHARGE = '[Giveaway] Select Charge';

export class StartEngagementFlow implements Action {
  readonly type = START_ENGAGEMENT_FLOW;

  constructor(public payload: string) {
  }
}

export class StartEditFlow implements Action {
  readonly type = START_EDIT_FLOW;

  constructor(public payload: any) {
  }
}

export class GetGiveawayDetails implements Action {
  readonly type = GET_GIVEAWAY_DETAILS;

  constructor(public payload: any) {
  }
}

export class GetGiveawayDetailsSuccess implements Action {
  readonly type = GET_GIVEAWAY_DETAILS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetGiveawayDetailsError implements Action {
  readonly type = GET_GIVEAWAY_DETAILS_ERROR;

  constructor(public payload: string) {
  }
}

export class SaveUpdatedGiveaway implements Action {
  readonly type = SAVE_UPDATED_GIVEAWAY;

  constructor(public payload: string = '') {
  }
}

export class SaveUpdatedGiveawaySuccess implements Action {
  readonly type = SAVE_UPDATED_GIVEAWAY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SaveUpdatedGiveawayError implements Action {
  readonly type = SAVE_UPDATED_GIVEAWAY_ERROR;

  constructor(public payload: string) {
  }
}

export class UpdateDates implements Action {
  readonly type = UPDATE_DATES;

  constructor(public payload: any = '') {
  }
}

export class UpdateGiveaway implements Action {
  readonly type = UPDATE_GIVEAWAY;

  constructor(public payload: any = '') {
  }
}

export class GetRewardTemplates implements Action {
  readonly type = GET_REWARD_TEMPLATES;

  constructor(public payload: any = '') {
  }
}

export class SelectReward implements Action {
  public type = SELECT_REWARD;

  constructor(public payload: any = '') {
  }
}

export class GetRewardTemplatesSuccess implements Action {
  readonly type = GET_REWARD_TEMPLATES_SUCCESS;

  constructor(public payload: any = '') {
  }
}

export class GetRewardTemplatesError implements Action {
  readonly type = GET_REWARD_TEMPLATES_ERROR;

  constructor(public payload: string) {
  }
}

export class CreateGiveaway implements Action {
  readonly type = CREATE_GIVEAWAY;

  constructor(public payload: any = '') {
  }
}

export class CreateGiveawaySuccess implements Action {
  readonly type = CREATE_GIVEAWAY_SUCCESS;

  constructor(public payload: any = '') {
  }
}

export class CreateGiveawayError implements Action {
  readonly type = CREATE_GIVEAWAY_ERROR;

  constructor(public payload: any = '') {
  }
}

export class CreateGiveawayDuplicate implements Action {
  readonly type = CREATE_GIVEAWAY_DUPLICATE;

  constructor(public payload: any = '') {
  }
}

export class ClearDuplicateGiveaways implements Action {
  readonly type = CLEAR_DUPLICATE_GIVEAWAYS;

  constructor(public payload: any = '') {
  }
}

export class ClearGiveawayData implements Action {
  readonly type = CLEAR_GIVEAWAY_DATA;

  constructor(public payload: any = '') {
  }
}

export class DeleteGiveaway implements Action {
  readonly type = DELETE_GIVEAWAY;

  constructor(public payload: any) {
  }
}

export class DeleteGiveawaySuccess implements Action {
  readonly type = DELETE_GIVEAWAY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class DeleteGiveawayError implements Action {
  readonly type = DELETE_GIVEAWAY_ERROR;

  constructor(public payload: any) {
  }
}

export class SelectGeofence implements Action {
  public type = SELECT_GEOFENCE;

  constructor(public payload: any = '') {
  }
}

export class SelectCharge implements Action {
  public type = SELECT_CHARGE;

  constructor(public payload: any = '') {
  }
}

export type Actions
  = StartEngagementFlow
  | StartEditFlow
  | GetGiveawayDetails
  | GetGiveawayDetailsSuccess
  | GetGiveawayDetailsError
  | SaveUpdatedGiveaway
  | SaveUpdatedGiveawaySuccess
  | SaveUpdatedGiveawayError
  | UpdateDates
  | UpdateGiveaway
  | GetRewardTemplates
  | SelectReward
  | GetRewardTemplatesSuccess
  | GetRewardTemplatesError
  | CreateGiveaway
  | CreateGiveawaySuccess
  | CreateGiveawayError
  | CreateGiveawayDuplicate
  | ClearDuplicateGiveaways
  | ClearGiveawayData
  | DeleteGiveaway
  | DeleteGiveawaySuccess
  | DeleteGiveawayError
  | SelectGeofence
  | SelectCharge;
