import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { GiveawayService } from '../../shared/service/giveaway.service';
import * as fromRoot from '../../store/reducers';
import * as giveaway from '../actions/giveaway.action';
import { GiveawayEngagementConstants } from '../../giveaway-engagement/giveaway-engagement.constants';

@Injectable()
export class GiveawayEffects {
  @Effect()
  getRewardTemplates$: Observable<any> = this.actions$.pipe(
    ofType(giveaway.GET_REWARD_TEMPLATES),
    switchMap(() => this.gs.getRewards().pipe(
      map((rewardTemplates) => new giveaway.GetRewardTemplatesSuccess(rewardTemplates)),
      catchError(() => of(new giveaway.GetRewardTemplatesError(GiveawayEngagementConstants.rewardTemplateError)))
    ))
  );

  @Effect()
  createGiveaway$: Observable<any> = this.actions$.pipe(
    ofType(giveaway.CREATE_GIVEAWAY),
    switchMap(() => this.store.select(fromRoot.getFormattedGiveaway).pipe(take(1))),
    switchMap((formattedGiveaway) => this.gs.createGiveaway(formattedGiveaway).pipe(
      map((createdGiveaway: any) => {
        // Handle successful api call that returns the error of it being a duplicate giveaway
        if (createdGiveaway.statusCode === 600) {
          return new giveaway.CreateGiveawayDuplicate(createdGiveaway['responseObject'])
        } else {
          return new giveaway.CreateGiveawaySuccess(createdGiveaway['responseObject'])
        }
      }),
      catchError((error) => of(new giveaway.CreateGiveawayError(error)))
    ))
  );

  @Effect()
  saveUpdatedGiveaway$: Observable<any> = this.actions$.pipe(
    ofType(giveaway.SAVE_UPDATED_GIVEAWAY),
    switchMap(() => this.store.select(fromRoot.getFormattedGiveaway).pipe(take(1))),
    switchMap((formattedGiveaway) => this.gs.updateGiveaway(formattedGiveaway).pipe(
      map((editedGiveaway) => new giveaway.SaveUpdatedGiveawaySuccess(editedGiveaway)),
      catchError((error) => of(new giveaway.SaveUpdatedGiveawayError(error)))
    ))
  );

  @Effect()
  deleteFutureGiveaway$: Observable<any> = this.actions$.pipe(
    ofType(giveaway.DELETE_GIVEAWAY),
    map((action: any) => action.payload),
    switchMap((giveawayToDelete) => this.gs.deleteGiveaway(giveawayToDelete).pipe(
      map((deletedGiveaway) => new giveaway.DeleteGiveawaySuccess(deletedGiveaway)),
      catchError((error) => of(new giveaway.DeleteGiveawayError(error)))
    ))
  );

  @Effect()
  GetGiveawayDetails$: Observable<any> = this.actions$.pipe(
    ofType(giveaway.GET_GIVEAWAY_DETAILS, giveaway.START_EDIT_FLOW),
    map((action: any) => action.payload),
    switchMap((payload) => this.gs.getGiveawayDetails(payload.id).pipe(
      map((giveawayDetails) => new giveaway.GetGiveawayDetailsSuccess(giveawayDetails)),
      catchError((error) => of(new giveaway.GetGiveawayDetailsError(error)))
    ))
  );

  @Effect({ dispatch: false })
  createGiveawaySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(giveaway.CREATE_GIVEAWAY_SUCCESS),
    map((action: any) => action.payload),
    tap((createdGiveaway) => {
      this.snackBar.open(
        `${createdGiveaway.claimDetails.title} was successfully created.`,
        'Dismiss', {
          duration: 5000
        }
      );
      window.scroll(0, 0);
      return this.router.navigate(['/home'])
    })
  );

  @Effect({ dispatch: false })
  saveUpdatedGiveawaySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(giveaway.SAVE_UPDATED_GIVEAWAY_SUCCESS),
    map((action: any) => action.payload),
    tap((updatedGiveaway) => {
      this.snackBar.open(
        `${updatedGiveaway.claimDetails.title} was successfully updated.`,
        'Dismiss', {
          duration: 5000
        }
      );
      return this.router.navigate(['/home'])
    })
  );

  @Effect({ dispatch: false })
  saveUpdatedGiveawayError$: Observable<Action> = this.actions$.pipe(
    ofType(giveaway.SAVE_UPDATED_GIVEAWAY_ERROR),
    tap(() => {
      return this.router.navigate(['/giveaway-review']);
    })
  );

  @Effect({ dispatch: false })
  deleteFutureGiveawaySuccess: Observable<Action> = this.actions$.pipe(
    ofType(giveaway.DELETE_GIVEAWAY_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Giveaway was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );
      return this.router.navigate(['/home']);
    })
  );

  @Effect({ dispatch: false })
  deleteFutureGiveawayFailure: Observable<Action> = this.actions$.pipe(
    ofType(giveaway.DELETE_GIVEAWAY_ERROR),
    tap(() => {
      this.snackBar.open(
        `Giveaway failed to delete. Please try again.`,
        'Dismiss', {
          duration: 5000
        }
      );
      return this.router.navigate(['/home']);
    })
  );

  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private snackBar: MatSnackBar,
              private router: Router,
              private gs: GiveawayService) {
  }
}
