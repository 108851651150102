export class GiveawayEngagementConstants {
  public static get dialogDisplayText(): string {
    return 'Once the claim and redemption period has started, you will not be able to edit the reward geofence, ' +
      'reward selection, or giveaway dates. Until the claim and redemption period begins, a Chick-fil-A One giveaway may ' +
      'be edited or cancelled by accessing "Scheduled Giveaways".';
    }

  public static get dialogConfirmText(): string {
    return 'Schedule';
  }

  public static get dialogDenyText(): string {
    return 'Cancel';
  }

  public static get dialogUpdateText(): string {
    return 'Are you sure you want to edit this giveaway?';
  }

  public static get dialogUpdateConfirmText(): string {
    return 'Yes, Edit Giveaway';
  }

  public static get createMode(): string {
    return 'create';
  }

  public static get reviewMode(): string {
    return 'review';
  }

  public static get editMode(): string {
    return 'edit';
  }

  public static get activeType(): string {
    return 'active';
  }

  public static get scheduledType(): string {
    return 'scheduled';
  }

  public static get pastType(): string {
    return 'past';
  }

  public static get rewardTemplateError(): string {
    return 'Reward templates failed to load, Please refresh and try again';
  }

  public static get rewardRedemptionRateStatCardHelpText(): string {
    return 'Reward redemption rate represents the percentage of guests who redeemed the reward during the redemption'
      + 'period divided by the number of guests who claimed the reward during the claim period.';
  }

  public static get redeemedByProductStatCardHelpText(): string {
    return 'Percentage redeemed by product represents the breakout of items offered during this giveaway.';
  }

  public static get redemptionMarketsStatCardHelpText(): string {
    return 'Redemption locations represents the number of rewards redeemed in-market vs. out of market.'
      + ' Refer to Giveaway Redemption Cross Charge setting below for redemptions cross charged to the market accrual.';
  }

  public static get createGiveawayErrorText(): string {
    return 'We are unable to complete your request at this time as an unknown error has occurred. Please try again.';
  }

  public static get crossChargeOptions(): any[] {
    return [{
      name: 'Redeeming Locations',
      description: 'Food and paper cost for redemptions within the MAP will be charged to the redeeming location. '
        + 'Cost for only those offers redeemed outside of the MAP will be charged to the MAP accrual.',
      value: false
    }, {
      name: 'MAP Accrual',
      description: 'Food and paper cost for all redemptions will be charged to the MAP accrual',
      value: true
    }];
  }

  public static get duplicateGiveawayColumns(): any[] {
    return [{
      prop: 'name',
      title: 'Reward Geofence'
    }, {
      prop: 'displayClaimStart',
      title: 'Claim and Redemption Start Date',
      mobile: 'title',
      type: 'claimStartDate'
    }, {
      prop: 'displayClaimEnd',
      title: 'Claim End Date'
    }, {
      prop: 'redemptionEndDate',
      title: 'Redemption End Date'
    }, {
      prop: 'treatName',
      title: 'Reward',
      mobile: 'subtitle'
    }];
  }

  public static get defaultPageSize(): number {
    return 5;
  }
}
