import * as markets from '../actions/markets.action';
import { filter, propEq } from 'ramda';

export interface State {
  userName: string,
  markets: any[],
  selectedMarket: any,
  globalBanner: any,
  maintenance: boolean,
  loading: boolean,
  authenticated: boolean,
}

export const initialState: State = {
  userName: null,
  markets: [],
  selectedMarket: {
    marketGeoFences: []
  },
  globalBanner: {},
  maintenance: false,
  loading: true,
  authenticated: false
};

export function reducer(state = initialState, action: markets.Actions): State {
  switch (action.type) {
    case markets.GET_MARKETS: {
      return { ...state, loading: true };
    }

    case markets.GET_MARKETS_SUCCESS: {
      const { markets, userLoginName } = action.payload;
      let selectedMarket = markets ? markets[0] : {};
      let globalBanner = selectedMarket.notificationBanners ? selectedMarket.notificationBanners[0] : {};
      const maintenance = selectedMarket.maintenance ? selectedMarket.maintenance : false;
      let userName = userLoginName ? userLoginName : '';

      return {
        ...state,
        userName,
        markets,
        selectedMarket,
        globalBanner,
        maintenance,
        loading: false,
        authenticated:true
      };
    }

    case markets.UPDATE_SELECTED_MARKET: {
      const selectedMarket = action.payload;
      const maintenance = selectedMarket.maintenance ? selectedMarket.maintenance : false;
      return { ...state, selectedMarket, maintenance };
    }

    case markets.UPDATE_SELECTED_MARKET_BY_ID: {
      const selectedMarketCode = action.payload;
      const selectedMarket = filter(propEq('marketCode', selectedMarketCode), state.markets)[0];
      const globalBanner = selectedMarket.notificationBanners ? selectedMarket.notificationBanners[0] : {};
      const maintenance = selectedMarket.maintenance ? selectedMarket.maintenance : false;

      return { ...state, selectedMarket, globalBanner, maintenance };
    }

    default:
      return state;
  }
}

export const getUserName = (state: State) => state.userName;

export const getMarkets = (state: State) => state.markets;

export const getSelectedMarket = (state: State) => state.selectedMarket;

export const getGlobalBanner = (state: State) => state.globalBanner;

export const getMaintenance = (state: State) => state.maintenance;

export const getLoading = (state: State) => state.loading;

export const getAuthenticated= (state: State) => state.authenticated;

export const getGeofences = (state: State) => state.selectedMarket.marketGeoFences;

