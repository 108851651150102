import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as fromRoot from '../../store/reducers';
import * as market from '../../store/actions/markets.action';
import {environment} from "../../../environments/environment";
import Bugsnag from "@bugsnag/js";
import {SharedConstants} from "../shared.constants";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, public store: Store<fromRoot.State>) {
  }

  public getUserMarkets() {
    return this.http.get(environment.cfa1gmApiUrl + '/api/user/markets').toPromise().then((markets) => {
      this.store.dispatch(new market.GetMarketsSuccess(markets));
    }).catch(error =>{
      Bugsnag.notify("CFA1GM Error Fetching markets");
    });
  }

  public dismissBanner() {
    const url = `/api/user/dismissBanner`;

    // Hardcoding bannerId for now since that is the only banner implemented in this app
    const bannerId = 'cfa1gm-banner';

    return this.http.post(environment.cfa1gmApiUrl + url, bannerId).pipe(
      map((giveaways) => giveaways['responseObject']),
      catchError((error) => throwError(error))
    );
  }
}
