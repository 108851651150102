<div class="flex-container"
     fxLayout="row"
     fxLayoutAlign="center center"
     *ngIf="!isDataAvailable() && !isLoading">
  <data-table-error [errorText]="noResultsMessage"
                    [actionableErrorText]="actionableErrorText"
                    (textClick)="errorTextClicked()">
  </data-table-error>
</div>
<div *ngIf="isDataAvailable()">
  <div class="flex-container ngx-data-table__row"
       fxLayout="row"
       [ngClass]="{'ngx-data-table__row-hover' : !isRowNonInteractive(),
                     'ngx-data-table__blue-row-background' : (i % 2  !== 0)}"
       *ngFor="let row of rows; let i = index">
    <div fxLayout="row" fxFlex="100">
      <div class="flex-item"
           [fxFlex]="getFlex()">
        <div *ngIf="showCheckboxes">
          <mat-checkbox
            *ngIf="showCheckboxes"
            [(ngModel)]="row.state"
            (change)="checkboxClick(row)"
            class="ngx-data-table__checkbox">
          </mat-checkbox>
          <mat-icon
            *ngIf="!row.open"
            (click)="openDetail(row)">
            keyboard_arrow_right
          </mat-icon>
          <mat-icon
            *ngIf="row.open"
            (click)="openDetail(row)">
            keyboard_arrow_down
          </mat-icon>
        </div>
      </div>
      <ng-container *ngFor="let col of columns">
                <span *ngIf="!col.hide"
                      class="flex-item ngx-data-table__padding"
                      [fxFlex]="getColSize(col)"
                      [ngSwitch]="col.type"
                      (click)="rowClicked(row)">
                    <data-table-cell [row]="row"
                                     [col]="col"
                                     (onDeleteClick)="deleteClicked(row)"
                                     (OnEditClick)="editClicked(row)">
                    </data-table-cell>
                </span>
      </ng-container>
      <mat-icon *ngIf="hasOnClickListeners"
                class="ngx-data-table__chevron"
                fxFlex="32px"
                (click)="rowClicked(row)">
        chevron_right
      </mat-icon>
      <div *ngIf="!hasOnClickListeners" fxFlex="32px"></div>
    </div>

    <div *ngIf="row.open"
         fxLayout="row"
         fxFlex="100"
         fxLayoutWrap>
      <div fxLayout="row"
           fxFlex="100"
           class="ngx-data-table__detail-header">
        <div fxFlex="55px"></div>
        <div fxFlex></div>
        <div fxFlex *ngFor="let detRowCol of detailColumns">
          {{detRowCol.title}}
        </div>
      </div>
      <div fxLayout="row"
           fxFlex="100"
           *ngFor="let detRow of row[detailProp]">
        <div fxFlex="55px"></div>
        <div fxFlex></div>
        <div fxFlex
             *ngFor="let detCol of detailColumns">
          <data-table-cell
            [row]="detRow"
            [col]="detCol">
          </data-table-cell>
        </div>
      </div>
    </div>
  </div>
</div>
