import { environment } from 'cfaone-giveaway-ui/environments/environment';

export default {
  oidc: {
    clientId: environment.oktaClientId,
    issuer: environment.oktaAuthIssuer,
    redirectUri: environment.uiRedirectUrl,
    endSessionRedirectUri: environment.endSessionRedirectUri,
    postLogoutRedirectUri: environment.endSessionRedirectUri,
    scopes: ['openid'],
    pkce: true,
  }
};
