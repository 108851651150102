<div class="filter">
  <div fxLayout="column"
       fxLayoutAlign="center start">
    <mat-form-field appearance="outline"
                    class="filter__search input-search-wrapper"
                    [ngClass]="{'filter__search-dt-resize': !isMobile, 'filter__search-mb-resize': isMobile}">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput
             type="search"
             placeholder="{{placeholderText}}"
             [(ngModel)]="searchTerm"
             (keyup)="searchTermChanged.emit(searchTerm)">
    </mat-form-field>
    <div class="filter__results-text">
      {{resultsText}}
    </div>
  </div>
</div>
