export class HomeConstants {
  public static get tabletScreenSizes(): string[] {
    return [
      '(max-width: 767px) and (orientation: landscape)',
      '(max-width: 767px) and (orientation: portrait)'
    ];
  }

  // Flexbox's gt-sm's cutoff is 960px, this will align with that determination of mobile
  public static get flexMobileScreenSizes(): string[] {
    return [
      '(max-width: 959px) and (orientation: landscape)',
      '(max-width: 959px) and (orientation: portrait)'
    ];
  }

  public static get mobileScreenSizes(): string[] {
    return [
      '(max-width: 599px) and (orientation: landscape)',
      '(max-width: 599px) and (orientation: portrait)'
    ];
  }

  public static get activeGiveawayColumns(): any[] {
    return [{
      prop: 'name',
      title: 'Reward Geofence',
      mobile: 'geofence',
      type: 'rewardGeofence',
    }, {
      prop: 'displayClaimStart',
      title: 'Claim and Redemption Start Date',
      mobile: 'title',
      type: 'claimStartDate'
    }, {
      prop: 'displayClaimEnd',
      title: 'Claim End Date',
      type: 'claimEndDate'
    }, {
      prop: 'redemptionEndDate',
      title: 'Redemption End Date'
    }, {
      prop: 'treatName',
      title: 'Reward',
      mobile: 'subtitle'
    }, {
      prop: 'amdContact',
      title: 'Created By'
    }, {
      type: 'edit',
      size: 5
    }];
  }

  public static get scheduledGiveawayColumns(): any[] {
    return [{
      prop: 'name',
      title: 'Reward Geofence',
      mobile: 'geofence',
      type: 'rewardGeofence'
    }, {
      prop: 'displayClaimStart',
      title: 'Claim and Redemption Start Date',
      mobile: 'title',
      type: 'claimStartDate'
    }, {
      prop: 'displayClaimEnd',
      title: 'Claim End Date',
      type: 'claimEndDate'
    }, {
      prop: 'redemptionEndDate',
      title: 'Redemption End Date'
    }, {
      prop: 'treatName',
      title: 'Reward',
      mobile: 'subtitle'
    }, {
      prop: 'amdContact',
      title: 'Created By'
    }, {
      type: 'edit',
      size: 5
    }, {
      type: 'delete',
      size: 5
    }];
  }

  public static get activeMarketsView(): string {
    return 'Active Market Giveaways';
  }

  public static get futureMarketsView(): string {
    return 'Future Market Giveaways';
  }

  public static get pastMarketsView(): string {
    return 'Past Market Giveaways';
  }

  public static get noMarketsResultsMessage(): string {
    return 'There are no claimable Chick-fil-A One giveaways in your market at this time.';
  }

  public static get scheduledActionableErrorText(): string {
    return 'You can schedule a new giveaway here.';
  }

  public static get noScheduleMarketsResultsMessage(): string {
    return 'There are no scheduled Chick-fil-A One giveaways at this time.';
  }

  public static get activeGiveawayError(): string {
    return 'Active giveaways failed to load, please refresh and try again.';
  }

  public static get pastGiveawayError(): string {
    return 'Past giveaways failed to load, please refresh and try again.';
  }

  public static get scheduledGiveawayError(): string {
    return 'Scheduled giveaways failed to load, please refresh and try again.';
  }

  public static get giveawayPerformanceError(): string {
    return 'There was a problem loading the giveaway performance stats, please try again later.';
  }

  public static get defaultPageSize(): number {
    return 5;
  }

  public static get rewardNoResultText(): string {
    return 'No Rewards Found.';
  }

  public static get rewardSearchPlaceholderText(): string {
    return 'Search Available Rewards';
  }
}
