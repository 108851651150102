import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { forEach } from 'ramda';

@Component({
  selector: 'data-table-body',
  templateUrl: './data-table-body.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableBodyComponent {
  @Input() public columns: any[];
  @Input() public detailColumns: any[];
  @Input() public detailProp: string;
  @Input() public actionableErrorText: string;
  @Input() public rows: any[];
  @Input() public noResultsMessage: string;
  @Input() public isLoading: boolean;
  @Input() public showCheckboxes: boolean;
  @Input() public rowIdProp: string;
  @Input() public hasOnClickListeners: boolean;

  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onCheckboxClick = new EventEmitter<any>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onEditClick = new EventEmitter<any>();

  public get isSelectable() {
    return !this.onCheckboxClick;
  }

  public rowClicked(rowData: any) {
    this.onRowClick.emit(rowData);
  }

  public openDetail(rowData) {
    rowData['open'] = !rowData.open;
  }

  public checkboxClick(row: any) {
    let lastRow = {}; // Only use last row with matching id to send consistent data
    forEach((dr) => {
      if (dr[this.rowIdProp] === row[this.rowIdProp]) {
        dr['state'] = row.state; // Make all rows with same id be the same state
        lastRow = dr;
      }
    })(this.rows);
    this.onCheckboxClick.emit(lastRow);
  }

  public isDataAvailable() {
    return this.rows
      && this.rows.length
      && this.rows.length > 0;
  }

  public errorTextClicked() {
    this.errorTextClick.emit();
  }

  public getFlex() {
    return this.showCheckboxes ? '55px' : '25px';
  }

  public getColSize(col) {
    // undefined doesn't work with fxFlex so need to return null
    return col.size ? col.size : null;
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public editClicked(row) {
    this.onEditClick.emit(row);
  }

  // returns true if row cannot be selected and the checkbox is either disabled or hidden
  public isRowNonInteractive() {
    return (!this.hasOnClickListeners && !this.showCheckboxes);
  }
}
