<div class="flex-container ngx-data-table__footer"
     fxLayout="row">
  <div class="flex-item" [ngClass]="{'ngx-data-table__mobile-footer': (isMobile || hidePageOptions())}" fxFlex
       fxLayoutAlign="end center">
    <!--<span *ngIf="!isMobile && !hidePageOptions()" fxLayoutAlign="end center">-->
    <!--<span>Page</span>-->
    <!--<mat-select-->
    <!--class="ngx-data-table__pagination-dropdown"-->
    <!--[(ngModel)]="currentPage"-->
    <!--(onClose)="changeCurrentPage(currentPage)">-->
    <!--<mat-option-->
    <!--*ngFor="let page of pageNumberArr; let i = index"-->
    <!--[value]="i">{{page}}</mat-option>-->
    <!--</mat-select>-->
    <!--<span>Rows per page:</span>-->
    <!--<mat-select-->
    <!--class="ngx-data-table__pagination-dropdown"-->
    <!--[(ngModel)]="pageSize"-->
    <!--(onClose)="pageSizeChange(pageSize)">-->
    <!--<mat-option-->
    <!--*ngFor="let size of pageSizeOptions"-->
    <!--[value]="size">-->
    <!--{{size}}-->
    <!--</mat-option>-->
    <!--</mat-select>-->
    <!--</span>-->

    <ng-container *ngIf="totalElements > 0">
      <button mat-button *ngIf="!isFirstPage()" (click)="pageLeft()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="ngx-data-table__page-navigation-space" *ngIf="isFirstPage()"></span>
      <span *ngIf="totalElements !== 0">
                {{getPageStart()}} - {{getPageEnd()}} of {{totalElements}}
            </span>
      <button mat-button *ngIf="!isLastPage()" (click)="pageRight()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <span class="ngx-data-table__page-navigation-space" *ngIf="isLastPage()"></span>
    </ng-container>
  </div>
</div>
