import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mobile-nav-footer',
  templateUrl: './mobile-nav-footer.component.html',
  styleUrls: ['./mobile-nav-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileNavFooterComponent {
  @Input() public backText: string;
  @Input() public confirmText: string;
  @Input() public disableConfirm: boolean = false;
  @Input() public isError: boolean = false;
  @Input() public errorButtonText: string;

  @Output() public backClicked = new EventEmitter<any>();
  @Output() public confirmClicked = new EventEmitter<any>();
  @Output() public errorButtonClicked = new EventEmitter<any>();
}
