import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fromRoot from '../../store/reducers';
import * as markets from '../actions/markets.action';
import { UserService } from '../../shared/service/user.service';

@Injectable()
export class MarketsEffects {

  @Effect()
  public dismissBanner$: Observable<any> = this.actions$.pipe(
    ofType(markets.DISMISS_BANNER),
    switchMap(() => this.us.dismissBanner().pipe(
      map((res) => new markets.DismissBannerSuccessful(res))
    ))
  );

  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private us: UserService) {
  }
}
