import { Injectable } from '@angular/core'

import { Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import Bugsnag from "@bugsnag/js";
import {SharedConstants} from "./shared/shared.constants";
import {environment} from "../environments/environment";




@Injectable()
export class NavigationService {



  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }

  async checkWasPerm(functionTag: string) {
    let token = this.oktaAuth.getAccessToken();
    if (!token){
      console.warn("No Access Token");
      return false;
    }
    const claims = this.oktaAuth.token.decode(token).payload;
    const perms = claims ? claims['cfa_perms']['MLG_MGR'] : undefined ;
    return perms? perms[functionTag] != undefined : false;
  }

  public signOut() {
    this.oktaAuth.signOut().then().catch(error =>{
      Bugsnag.notify("Logout Error");
    });
  }

  public async login(){
    Bugsnag.start({
      apiKey: SharedConstants.bugsnagApiKey,
      autoTrackSessions: false,
      maxBreadcrumbs: 100,
      maxEvents: 100,
      projectRoot: '/cfaone-giveaway-ui',
      releaseStage: environment.env,
      trackInlineScripts: false
    });
    return this.oktaAuth.getAccessToken() != undefined;
  }

}
