import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpTextComponent {
  @Input() public helpText: string;
  @Input() public displayOnLeft: boolean = false;

  public show: boolean = false;

  public showHelpText() {
    this.show = true;
  }

  public hideHelpText() {
    this.show = false;
  }
}
