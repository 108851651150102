import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from '../modules/material.module';

import { TruncatePipe } from './pipes/truncate.pipe';

import { ErrorMessageComponent } from './error-message/error-message.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MobileDataTableComponent } from './data-table/mobile-data-table/mobile-data-table.component';
import { DataTableBodyComponent } from './data-table/data-table-body/data-table-body.component';
import { DataTableFooterComponent } from './data-table/data-table-footer/data-table-footer.component';
import { DataTableHeaderComponent } from './data-table/data-table-header/data-table-header.component';
import { DataTableCellComponent } from './data-table/data-table-cell/data-table-cell.component';
import { DataTableErrorComponent } from './data-table/data-table-error/data-table-error.component';
import { ActionableBoxComponent } from './actionable-box/actionable-box.component';
import { ContentPlaceholderComponent } from './content-placeholder/content-placeholder.component';
import { HelpTextComponent } from './help-text/help-text.component';
import { MobileNavFooterComponent } from './mobile-nav-footer/mobile-nav-footer.component';
import { SimpleNavComponent } from './simple-nav/simple-nav.component';
import { GiveawayHtmlPreviewComponent } from './giveaway-html-preview/giveaway-html-preview.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FilterComponent } from './filter/filter.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    FlexLayoutModule,
    MatMomentDateModule
  ],
  declarations: [
    TruncatePipe,
    ErrorMessageComponent,
    DataTableComponent,
    MobileDataTableComponent,
    DataTableBodyComponent,
    DataTableFooterComponent,
    DataTableHeaderComponent,
    DataTableCellComponent,
    DataTableErrorComponent,
    ActionableBoxComponent,
    ContentPlaceholderComponent,
    HelpTextComponent,
    MobileNavFooterComponent,
    SimpleNavComponent,
    GiveawayHtmlPreviewComponent,
    ConfirmationDialogComponent,
    FilterComponent
  ],
  exports: [
    TruncatePipe,
    ErrorMessageComponent,
    DataTableComponent,
    MobileDataTableComponent,
    DataTableBodyComponent,
    DataTableFooterComponent,
    DataTableHeaderComponent,
    DataTableCellComponent,
    DataTableErrorComponent,
    ActionableBoxComponent,
    ContentPlaceholderComponent,
    HelpTextComponent,
    MobileNavFooterComponent,
    SimpleNavComponent,
    GiveawayHtmlPreviewComponent,
    ConfirmationDialogComponent,
    FilterComponent
  ]
})
export class SharedModule {
}
