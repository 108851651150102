import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HomeConstants } from '../../home/home.constants';
import * as fromRoot from '../../store/reducers';
import { Market } from '../../models/Market';
import {environment} from "../../../environments/environment";

@Injectable()
export class HomeService {
  public selectedMarket$: Observable<Market>;

  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {
    this.selectedMarket$ = store.select(fromRoot.getSelectedMarket);
  }

  public getCreatedGiveaways(view) {
    let status = this.getMarketStatus(view);
    return this.selectedMarket$.pipe(switchMap((market) => {
      let marketCode = market.marketCode;
      let locationNumber = market.locationNumber;
      let url = `/api/market/${marketCode}/${locationNumber}/marketLevelGiveaways`;
      if (status !== '') {
        url += `?status=${status}`;
      }
      return this.http.get(environment.cfa1gmApiUrl + url).pipe(
        map((giveaways) => giveaways['responseObject']),
        catchError((error) => throwError(error))
      );
    }));
  }

  public getGiveawayPerformances() {
    return this.selectedMarket$.pipe(switchMap((market) => {
      let marketCode = market.marketCode;
      let locationNumber = market.locationNumber;

      return this.http.get(environment.cfa1gmApiUrl + `/api/market/${marketCode}/${locationNumber}/performanceStats`).pipe(
        map((giveaways) => giveaways['responseObject']),
        catchError((error) => throwError(error.statusText))
      )
    }));
  }

  private getMarketStatus(view) {
    switch (view) {
      case HomeConstants.activeMarketsView: {
        return 'CURRENT';
      }

      case HomeConstants.pastMarketsView: {
        return 'EXPIRED';
      }

      case HomeConstants.futureMarketsView: {
        return 'FUTURE';
      }

      default: {
        return '';
      }
    }
  }
}
