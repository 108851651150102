import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocationStrategy, PathLocationStrategy} from '@angular/common';
import {OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';
import {RoleGuard} from "../roleGuard.service";

const routes: Routes = [
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  { path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: '#',
    redirectTo: 'home'
  },
  { path: 'saml',
    redirectTo: 'home'
  },
  {
    path: 'home',
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'maintenance',
    loadChildren: () => import('../maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'giveaway-engagement',
    loadChildren: () => import('../giveaway-engagement/giveaway-engagement.module').then(m => m.GiveawayEngagementModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'giveaway-review',
    loadChildren: () => import('../giveaway-review/giveaway-review.module').then(m => m.GiveawayReviewModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  {
    path: 'past-giveaway',
    loadChildren: () => import('../past-giveaways/past-giveaways.module').then(m => m.PastGiveawaysModule),
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {roles: ['LOGIN']}
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})

export class AppRoutingModule {
}
